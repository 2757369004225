import { CfSectionProductsList } from 'interfaces/Contentful/CfSection';
import { FC } from 'react';
import { ProductCard } from 'components/product';

interface Props {
  content: CfSectionProductsList;
}

const SectionProductsList: FC<Props> = ({ content }) => {

  if (!content.products) {
    return null;
  }

  return (
    <section className='padding-y-xl'>
      <div className='container max-width-md'>
        <div className='text-component margin-bottom-sm'>
          <h2 className='font-medium'>{content.title}</h2>
        </div>
        {content.products && (
          <div className='grid gap-sm'>
            {content.products.items.map((p) => (
              <div key={p.id} className={'col-12 col-6@xs col-3@md'}>
                <ProductCard product={p} />
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

export default SectionProductsList;
